/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* fontface.less 2013-1-16 *******/
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'cbfont';
  src: url('/font/cbfont.eot');
  src: url('/font/cbfont.eot?#iefix') format('embedded-opentype'), url("/font/cbfont.woff") format('woff'), url("/font/cbfont.ttf") format('truetype'), url("/font/cbfont.svg#cbfont") format('svg');
}
@font-face {
  font-family: "Noto Sans";
  src: url("/extras/fonts/NotoSans-VariableFont_wdth,wght.woff2") format("woff2-variations");
  font-weight: 300 700;
  font-stretch: 75% 100%;
  font-style: normal;
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2013-1-16 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 15px;
  margin-bottom: 15px;
}
.unit > .body,
.unit > form > .body {
  margin: 0 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0;
  margin-bottom: 0;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #4d4d4d;
  color: #4d4d4d;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 14px;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #000 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #b3b3b3;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 1px;
}
.vert td,
.vert th {
  padding: 1px 4px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid thead td,
.grid thead th,
.grid tfoot td {
  font-weight: bold;
  background: #ccc;
}
.grid th {
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #e6e6e6;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #404040;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: bold;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
#disp.srch a:visited {
  color: #000000;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #e2001a;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #e2001a;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 1001px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: #e6e6e6;
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 1px 4px;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: bold;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 1px 4px;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 1px 4px;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(226, 0, 26, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 20px;
  font-size: 2rem;
  width: 100%;
  background-color: transparent;
  color: #000;
  font-family: 'Noto Sans', helvetica, sans-serif;
  line-height: 1.2;
  font-weight: 300;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 92%;
  width: 1500px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
#home {
  width: 178px;
  display: block;
  margin: 30px auto 0;
}
.logo {
  width: 100%;
  height: 52px;
}
#head {
  float: left;
  width: 100%;
}
div.cb-form-sent {
  float: left;
  width: 100%;
  text-decoration: none;
}
div.cb-form-sent a {
  text-decoration: none;
}
#wrapper {
  float: left;
  width: 100%;
}
#header {
  float: left;
  width: 100%;
}
#navigation {
  float: left;
  width: 100%;
}
#placeholder {
  float: left;
  width: 100%;
}
#multimood {
  float: left;
  margin-top: 30px;
  width: 100%;
  position: relative;
}
#maincontent {
  float: left;
  width: 100%;
}
#footer {
  float: left;
  width: 100%;
  margin-top: 140px;
  background: #212121;
}
.foot-part {
  float: left;
  width: 100%;
}
.foot-part .dock {
  float: left;
  width: 100%;
}
.foot-part .meta {
  color: #fff;
  display: block;
  text-decoration: none;
  font-size: 11px;
  padding-left: 12px;
  background: url(/images/foot-slash.png) no-repeat 0 50%;
}
.foot-part .meta:hover,
.foot-part .meta:focus {
  color: #e2001a;
}
.foot-part .foot-title {
  float: left;
  width: 100%;
  color: #fff;
  letter-spacing: 1.2px;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-size: 11px;
  font-stretch: 75%;
  font-weight: bold;
}
.foot-part .foot-text {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 11px;
}
#footservice6 .meta {
  float: left;
  width: 49px;
  height: 33px;
  background: url(/images/viscom.png) no-repeat 0 0;
  padding: 0;
  margin-top: 7px;
  overflow: hidden;
  text-indent: -9999px;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  margin-right: 7px;
}
#footservice6 .meta:hover,
#footservice6 .meta:focus {
  opacity: 0.6;
}
#footservice6 .meta.service_sqs {
  width: 46px;
  height: 47px;
  margin-top: 0;
  background: url(/images/sqs.png) no-repeat 0 0;
}
#footservice6 .meta.service_fsc {
  width: 104px;
  margin-top: 3px;
  height: 42px;
  margin-right: 0;
  background: url(/images/fsc-2024-09-27.jpg) no-repeat 0 0;
  background-size: 104px 39px;
}
#footservice6 .meta.service_viscom:hover,
#footservice6 .meta.service_sqs:hover,
#footservice6 .meta.service_viscom:focus,
#footservice6 .meta.service_sqs:focus {
  cursor: default;
  opacity: 1;
}
a.blog {
  display: none;
  position: fixed;
  top: 0;
  z-index: 10;
  right: 0;
  background: #e2001a url(/images/blog.svg) no-repeat 0 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
  padding: 8px 24px 9px 48px;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
a.blog:hover,
a.blog:focus {
  padding-right: 74px;
  padding-left: 24px;
  color: #fff;
}
#mobile-navi a.blog {
  display: block;
  position: relative;
  padding: 9px 26px 9px 50px;
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
  float: left;
  background: none;
  text-decoration: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: bold;
  width: 100%;
}
#mobile-navi a.blog:hover,
#mobile-navi a.blog:focus {
  background: #e2001a;
}
h1 {
  font-size: 40px;
  color: #000;
  text-align: center;
  font-weight: bold;
  font-stretch: 75%;
  border-bottom: 4px solid #eee;
  padding: 30px 0;
}
h2 {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
}
h3 {
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  padding: 8px 0 0;
}
h4 {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  background: #e2001a;
  padding: 2px 5px;
  color: #fff;
  margin-top: -16px;
  margin-bottom: 20px;
}
h5 {
  font-size: 12px;
  color: #808080;
  font-weight: 300;
  text-transform: uppercase;
}
h6 {
  font-size: 20px;
  font-weight: bold;
}
#view h6 {
  margin-bottom: -8px;
}
strong {
  font-weight: bold;
}
.pale {
  text-align: center;
}
.skew {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
.text {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.open,
.load {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.gist,
.dent {
  margin-left: 0;
  list-style-type: none;
  padding-left: 20px;
  background: url(/images/list.png) no-repeat 0 4px;
}
a {
  color: #000;
  text-decoration: underline;
  -o-transition: color 0.218s;
  -moz-transition: color 0.218s;
  -webkit-transition: color 0.218s;
  transition: color 0.218s;
}
a:hover,
a:focus {
  color: #e2001a;
}
.link {
  background: url('/icon-link/link.gif') no-repeat 0 0.2em;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.2em;
}
.load {
  background-position: 0 0.2em;
}
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
#view .loud a.open[href*='linkedin.com'],
#view .loud a.open[href*='xing.com'] {
  float: left;
  margin-right: 10px;
  display: block;
  width: 24px;
  height: 24px;
  margin-left: 0;
  overflow: hidden;
  text-indent: -9999px;
  background: url(/images/social.png) no-repeat 0 0;
}
#view .loud a.open[href*='linkedin.com']:hover,
#view .loud a.open[href*='xing.com']:hover {
  opacity: 0.6;
}
#view .loud a.open[href*='linkedin.com'] {
  background-position: -24px 0;
}
#edit div.base {
  border-top: 3px dashed #ccc;
}
#maincontent div.area div.seam {
  border-bottom: 4px solid #eee;
  margin-top: 17px;
  margin-bottom: 17px;
}
#maincontent div.area div.seam div.body {
  margin-top: 0;
  margin-bottom: 0;
}
#maincontent div.area div.seam div.part {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
div.unit.fold {
  color: #fff;
}
div.unit.fold a.open {
  color: #fff;
  text-decoration: none;
}
div.unit.fold a.open:hover,
div.unit.fold a.open:focus {
  text-decoration: underline;
}
div.unit.fold div.head h2 {
  background: #e2001a;
  padding: 5px 15px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
div.unit.fold div.part {
  margin-top: 0;
  margin-bottom: 0;
  background: #e2001a;
  padding: 15px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
div.unit.fold div.ctrl a {
  position: absolute;
  right: 6.66666667%;
  top: 10px;
  width: 18px;
  height: 18px;
  display: block;
  overflow: hidden;
  text-indent: -9999px;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  background: url(/images/cross-open.png) no-repeat 92% 50%;
}
div.unit.fold div.ctrl a.less {
  z-index: 5;
  background: url(/images/cross.png) no-repeat 92% 50%;
}
div.unit.fold div.ctrl a.less.cb-invisible {
  z-index: 0;
}
#view div.fold div.ctrl {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
#view div.fold div.ctrl a {
  width: 100%;
  height: 39px;
  display: block;
  top: 0;
  right: 0;
}
div.form input.submit {
  border-radius: 0;
  background: #e2001a;
  border: none;
  padding: 0;
  min-height: 0;
  color: #fff;
  text-shadow: none;
  font-size: 14px;
  text-transform: uppercase;
  padding: 9px 24px 8px;
  line-height: 1.2;
}
div.form input.submit:hover,
div.form input.submit:focus {
  background: #235d97;
}
div.form input.submit:active {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) inset;
}
.grow {
  text-transform: none !important;
}
/* @group EDIT MODE */
/* unit edge.wide is layout helper */
#maincontent .area .edge.wide {
  width: 100%;
}
#edit #maincontent .area .edge.wide {
  color: #ccc;
  position: relative;
  border-top: 2px dashed #ddd;
}
#edit #maincontent .area .edge.wide:after {
  top: 10px;
  left: 10px;
  color: #444;
  font-size: 11px;
  background: #eee;
  position: absolute;
  border-radius: 3px;
  padding: 0 0.5em 1px;
  letter-spacing: 0.03em;
  content: 'Layout-Helper';
  text-shadow: 0 1px 0 #fff;
  font-family: 'Helvetica Neue', sans-serif;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3), 0 0 0 1px #ddd inset, 0 0 0 2px #fff inset;
}
#edit #maincontent .area .edge.wide h2 {
  color: #ccc;
}
#maincontent .area div.edge.wide,
#maincontent .area div.edge.wide div.body {
  margin-top: 0;
  min-height: 0;
  margin-bottom: 0;
}
/* @end */
#hj-contenttop,
#hj-contentbottom {
  float: left;
  width: 100%;
  padding: 20px 0;
}
div.part.code .seam {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
.hj-title {
  float: left;
  width: 100%;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
}
.hj-part {
  float: left;
  height: 245px;
  position: relative;
  width: 31.33333333%;
  overflow: hidden;
  margin-top: 1.5%;
  margin-bottom: 1.5%;
}
.hj-part.nonvisible {
  display: none !important;
}
.hj-video {
  height: 100% !important;
}
.hj-video--local {
  width: 100%;
}
.hj-part .branding {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 5;
  color: #fff;
  font-size: 12px;
}
.hj-part .imagewidth {
  float: left;
  width: 100%;
  position: relative;
  left: 50%;
}
.hj-part img {
  float: left;
  width: 100%;
  margin-left: -235px;
  width: 470px;
  height: 266px;
  height: auto;
}
.hj-part.half img {
  width: 727px;
  margin-left: -363px;
}
.hj-part .textheight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 34%;
  top: 33%;
  display: table;
  -o-transition: all 0.218s cubic-bezier(0.05, 0.31, 0.22, 1.68);
  -moz-transition: all 0.218s cubic-bezier(0.05, 0.31, 0.22, 1.68);
  -webkit-transition: all 0.218s cubic-bezier(0.05, 0.31, 0.22, 1.68);
  transition: all 0.218s cubic-bezier(0.05, 0.31, 0.22, 1.68);
}
.hj-part .textheight.red {
  background: url(/images/hj/red.png) repeat 0 0;
}
.hj-part .textheight.blue {
  background: url(/images/hj/blue.png) repeat 0 0;
}
#hj-contenttop .hj-part .textheight {
  height: 100%;
  top: 0;
  background: none;
}
.hj-part .imgtext {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 26px;
  text-transform: uppercase;
  color: #fff;
  padding: 0 10%;
}
.hj-part .imgtext .slogan {
  font-size: 24px;
  text-transform: none;
}
#hj-contenttop .hj-part .imgtext {
  display: block;
  padding-top: 10px;
}
.hj-part .fadetext {
  color: #fff;
  text-align: center;
  font-size: 22px;
  float: left;
  margin-top: 8px;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  display: none;
}
.hj-part.part9 .fadetext {
  margin-top: 39px;
}
.hj-part:hover .textheight {
  height: 100%;
  top: 0;
  -o-transition: all 0.118s ease-out;
  -moz-transition: all 0.118s ease-out;
  -webkit-transition: all 0.118s ease-out;
  transition: all 0.118s ease-out;
}
#hj-contenttop .hj-part:hover .textheight.blue {
  background: url(/images/hj/blue.png) repeat 0 0;
}
#hj-contenttop .hj-part:hover .textheight.red {
  background: url(/images/hj/red.png) repeat 0 0;
}
.hj-part:hover .fadetext {
  display: block;
}
div.hj-part {
  background: #dbe6ec;
}
.hj-part form {
  float: left;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 5%;
  padding-right: 5%;
}
.hj-part form label,
.hj-part form input {
  float: left;
  width: 100%;
  color: #adafaf;
  font-size: 24px;
}
.hj-part form input {
  min-height: 0;
  line-height: 1.2;
  border: none;
  box-shadow: none;
  border-radius: 0;
  color: #000;
}
.hj-part form input.submit {
  width: auto;
  float: right;
  margin: 0;
  color: #adafaf;
  margin-top: 15px;
  background: none;
  padding: 0;
  text-shadow: none;
}
.hj-part form input.submit:hover,
.hj-part form input.submit:focus {
  color: #e2001a;
}
.hj-part form input.submit:active {
  color: #af0014;
  box-shadow: none;
}
.hj-part form label {
  padding-bottom: 2px;
  margin-top: 5px;
}
.hj-part .logintitle {
  float: left;
  width: 100%;
  margin-top: 10px;
  color: #adafaf;
  font-size: 24px;
  padding-bottom: 5px;
  text-align: center;
}
/******* forms.less 2013-1-16 *******/
fieldset {
  line-height: 2.2em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #404040;
  background-color: #fff;
  font-weight: normal;
  font-family: 'Noto Sans', helvetica, arial, sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.16666667em 3px;
  min-height: 2.2em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 12px;
  font-size: 1.2rem;
  color: #444;
  font-weight: normal;
  font-family: 'Noto Sans', helvetica, arial, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #8c8c8c;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.16666667em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #bfbfbf #a6a6a6 #a6a6a6 #bfbfbf;
  border-radius: 2px;
  background-color: #ccc;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(153, 153, 153, 0.75);
  line-height: 2.2em;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  border-color: #bfbfbf #a6a6a6 #a6a6a6 #bfbfbf;
  background-color: #d4d4d4;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  border-color: #bfbfbf #a6a6a6 #a6a6a6 #bfbfbf;
  background-color: #b3b3b3;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 2.2em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick input.radio,
.tick input.checkbox {
  float: left;
  margin-top: 0;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
  padding-bottom: 0.2em;
}
.form .ctrl .sign {
  display: block;
  margin-left: 20px;
}
div.cb-form-sent {
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
/* input.fade,
select.fade,
textarea.fade { // if !important added: fix shop input.payp.fade
    &,
    &:hover,
    &:focus,
    &:active {
    border-color: lighten(@input-border,25); */
/* background-color: @lightergray; */
/* background-image: none; */
/* color: lighten(@input-color, 35); */
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 54.13333333%;
  position: relative;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  float: left;
  width: 100%;
  height: 0;
}
@media (max-width: 639px) {
  #expo div.link {
    display: none;
  }
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 33px;
  height: 55px;
  transform: translateY(-50%);
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
#expo a.link.prev {
  left: 0;
  background-image: url(/images/mood-prev.svg);
}
#expo a.link.next {
  right: 0;
  background-image: url(/images/mood-next.svg);
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
  display: none;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #e2001a;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
/******* navigation-small.less 2013-1-16 *******/
.navi,
#find,
.dock {
  display: none;
}
.cb-mobile-icon-sitemap:before,
.cb-mobile-icon-meta:before,
.cb-mobile-icon-search:before {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-decoration: inherit;
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  font-family: 'cbfont';
  line-height: 1em;
  speak: none;
}
.cb-mobile-icon-meta:before {
  content: '\e715';
}
.cb-mobile-icon-sitemap:before {
  content: '\f0c9';
}
.cb-mobile-icon-search:before {
  content: '\1f50d';
}
.cb-mobile-navigation-content-search .submit {
  display: none;
}
.cb-mobile-navigation {
  font-size: 16px;
  font-size: 1.6rem;
  position: fixed;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  z-index: 20;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  background: #e2001a;
  color: #555;
  opacity: 1;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
}
.cb-mobile-navigation-items {
  padding: 0.2em 2%;
  /* was 0 */
  width: 96%;
}
.cb-mobile-content {
  font-size: 16px;
  font-size: 1.6rem;
  float: left;
  overflow: hidden;
  /* full width padding trick, no overlay of closed sitemap */
  max-height: 0;
  /* animation */
  width: 100%;
  line-height: 1.2;
  -webkit-transition: max-height 0.4s ease-out;
  -moz-transition: max-height 0.4s ease-out;
  -o-transition: max-height 0.4s ease-out;
  transition: max-height 0.4s ease-out;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}
.cb-mobile-content-open {
  max-height: 3000px;
  -webkit-transition: max-height 0.218s ease-in;
  -moz-transition: max-height 0.218s ease-in;
  -o-transition: max-height 0.218s ease-in;
  transition: max-height 0.218s ease-in;
}
.cb-mobile-navigation-item {
  display: inline-block;
  float: left;
}
.cb-mobile-navigation-item + .cb-mobile-navigation-item {
  margin-left: 0.2em;
}
.cb-mobile-link {
  display: block;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  color: #fff;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.4);
  overflow: hidden;
  width: 2.6em;
  background: none !important;
  /* no hover */
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  /* no href */
}
.cb-mobile-link:hover,
.cb-mobile-link:focus,
.cb-mobile-link:active {
  background: #c90017;
  color: #ffffff;
  text-decoration: none;
}
.cb-mobile-icon-meta:before,
.cb-mobile-icon-search:before {
  padding: 0;
  width: 100%;
}
.cb-mobile-sitemap-items:first-child > .cb-mobile-sitemap-item {
  display: block;
}
.cb-mobile-sitemap-item.path,
.path > .cb-mobile-sitemap-items > .cb-mobile-sitemap-item {
  display: block;
}
.cb-mobile-sitemap-item > ul {
  float: left;
  width: 100%;
  font-size: 90%;
  /* overwritten in first ul */
}
.cb-mobile-navigation-content-sitemap > ul {
  overflow: hidden;
  /* link padding overflow trick */
  font-size: 1em;
}
.cb-mobile-sitemap-item > a,
.cb-mobile-meta-item > .meta {
  display: block;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  color: #fff;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.4);
  position: relative;
  left: -48%;
  float: left;
  padding-right: 50%;
  padding-left: 50%;
  width: 100%;
}
.cb-mobile-sitemap-item > a:hover,
.cb-mobile-meta-item > .meta:hover,
.cb-mobile-sitemap-item > a:focus,
.cb-mobile-meta-item > .meta:focus,
.cb-mobile-sitemap-item > a:active,
.cb-mobile-meta-item > .meta:active {
  background: #c90017;
  color: #ffffff;
  text-decoration: none;
}
.cb-mobile-sitemap-item.path > a {
  background: #c90017;
  color: #ffffff;
  text-decoration: none;
}
.cb-mobile-sitemap-item.here > a {
  background-color: #b90015;
}
.cb-mobile-filter-items > .epoc thead {
  display: table-caption;
  width: 100%;
}
.cb-mobile-filter-items > .epoc thead > tr {
  display: table;
  width: 100%;
}
.cb-mobile-filter-items > .epoc th {
  width: 33.33333333%;
  display: table-cell;
}
.cb-mobile-filter-items > .epoc tbody > tr {
  width: 25%;
  display: table-cell;
}
.cb-mobile-filter-items > .epoc td {
  display: block;
  text-align: center;
}
.cb-mobile-find {
  padding: 0.6em 2%;
  width: 96%;
}
.cb-mobile-find #searchfield {
  padding: 0.5em 2%;
  width: 96%;
}
.cb-mobile-find .image {
  display: none;
}
.cb-mobile-find > fieldset > input {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
  width: 100%;
}
@media only screen and (min-width: 15em) {
  .cb-mobile-navigation-items {
    text-align: left;
  }
  .cb-mobile-navigation-link-sitemap {
    padding-right: 0.4em;
    padding-left: 0.2em;
    width: inherit;
  }
  .cb-mobile-icon-sitemap:before {
    padding: 0 0.2em;
    width: 1.2em;
  }
  .cb-mobile-sitemap-item > ul {
    float: left;
    padding-left: 6%;
    font-size: 100%;
  }
  .cb-mobile-navigation-item-search,
  .cb-mobile-navigation-item-meta {
    position: relative;
    /* variant */
    float: right;
  }
  .cb-mobile-navigation-item-meta {
    left: -2.8em;
  }
  .cb-mobile-navigation-item-meta:last-child {
    left: 0;
  }
  .cb-mobile-navigation-item-search {
    left: 2.8em;
  }
  .cb-mobile-sitemap-item > ul {
    padding-left: 2em;
  }
}
/******* layout-small.less 2013-1-16 *******/
#navigation {
  display: none;
}
#footer .foot-part {
  width: 100%;
  padding: 20px 4%;
  margin-left: -4%;
  border-bottom: 2px solid #fff;
}
#footer .foot-part .dock {
  display: block;
}
#footer .foot-part.third,
#footer .foot-part.fifth {
  display: none;
}
#footer .foot-part.fourth {
  border-bottom: none;
}
#footer .foot-part .foot-title.plus {
  margin-top: 20px;
  border-top: 2px solid #fff;
  padding: 20px 4% 0;
  margin-left: -4%;
}
.hj-part {
  width: 100%;
}
.hj-part img {
  width: 588px;
  margin-left: -294px;
}
@media only screen and (max-width: 1023px) {
  .hj-part .imgtext {
    font-size: 20px;
  }
  .hj-part .imgtext .slogan {
    font-size: 18px;
  }
  .hj-part .fadetext {
    font-size: 18px;
  }
}
.hj-part .fadetext {
  font-size: 16px;
}
#social {
  display: block;
  position: absolute;
  top: -78px;
  left: 0;
  width: 100%;
  text-align: center;
}
#social .meta {
  display: inline-block;
  width: 48px;
  margin: 0 5px;
  height: 48px;
  text-indent: -9999px;
  overflow: hidden;
  transition: all 0.218s;
  background-size: 240px 48px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(/images/social-footer-2020-12-22.svg);
}
#social .meta:hover,
#social .meta:focus {
  opacity: 0.6;
}
#social .meta.service_xing {
  background-position: -48px 0;
}
#social .meta.service_linkedin {
  background-position: -96px 0;
}
#social .meta.service_facebook {
  background-position: -144px 0;
}
#social .meta.service_instagram {
  background-position: -192px 0;
}
@media only screen and (max-width: 640px) {
  #hj-konzept {
    max-width: 191px !important;
    max-height: 928px !important;
    margin: 0 auto;
  }
  #hj-crossmedia2 {
    max-width: 200px !important;
    max-height: 616px !important;
    margin: 0 auto;
  }
  #hj-webapp {
    max-width: 306px !important;
    max-height: 444px !important;
    margin: 0 auto;
  }
  h3 {
    font-size: 20px;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.70940171%;
  margin-left: 1.70940171%;
}
.area .part,
.area > .grid table {
  margin-right: 1.70940171%;
  margin-left: 1.70940171%;
  width: 96.58119658%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 96.58119658%;
}
.area {
  margin-left: -1.73913043%;
  width: 103.47826087%;
}
div.main div.seam {
  width: 96.58119658%;
  margin-left: 1.70940171%;
  margin-right: 1.70940171%;
}
@media only screen and (max-width: 1000px) {
  div.base div.slim div.pict img {
    margin: 0 auto;
    max-width: 186px !important;
  }
}
div.base div.seam {
  width: 95.04132231%;
  margin-left: 2.47933884%;
  margin-right: 2.47933884%;
}
h1 {
  font-size: 28px;
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 3% !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
#disp.cb-shoppingcart {
  left: 0%;
  margin-left: 0;
  width: 100%;
  min-width: 340px;
}
h1,
h2 {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.cb-toggle {
  display: block;
  width: 100%;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  background: url(/images/navi-close.svg) no-repeat 93% 50%;
  -o-transition: rotate 0.218s;
  -moz-transition: rotate 0.218s;
  -webkit-transition: rotate 0.218s;
  transition: rotate 0.218s;
}
.cb-toggle:hover,
.cb-toggle:focus {
  cursor: pointer;
}
.cb-toggle.cb-toggle-active {
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  width: 40px;
  height: 40px;
  background-position: 50% 50%;
}
.open-navi {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 4%;
  width: 40px;
  height: 40px;
  background: url(/images/mnavi.svg) no-repeat 0 0;
}
.open-navi.cb-toggle-active {
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
#mobile-navi .navi > .cb-toggle,
#mobile-navi .navi .item-empty > .cb-toggle {
  display: none;
}
#mobile-navi {
  position: fixed;
  left: 4%;
  top: 0;
  z-index: 9;
  width: 250px;
  max-width: 0;
  max-height: 0;
  background: #4d4d4d;
  overflow: hidden;
  -o-transition: all 0.218s ease-in;
  -moz-transition: all 0.218s ease-in;
  -webkit-transition: all 0.218s ease-in;
  transition: all 0.218s ease-in;
}
#mobile-navi.cb-toggle-target-active {
  -o-transition: all 0.218s ease-out;
  -moz-transition: all 0.218s ease-out;
  -webkit-transition: all 0.218s ease-out;
  transition: all 0.218s ease-out;
  max-width: 250px;
  max-height: 244px;
  overflow-y: scroll;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}
#mobile-navi .navititle {
  float: left;
  width: 200px;
  color: #fff;
  padding: 9px 0 9px 50px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid #9b9b9b;
}
#mobile-navi div.sub1 {
  float: left;
  width: 100%;
  overflow: hidden;
  display: block;
}
#mobile-navi div.sub1 .item {
  float: left;
  position: relative;
  width: 100%;
}
#mobile-navi div.sub1 .menu {
  display: block;
  padding: 9px 26px 9px 50px;
  text-transform: uppercase;
  font-size: 18px;
  white-space: nowrap;
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #9b9b9b;
  font-weight: bold;
}
#mobile-navi div.sub1 .menu:hover,
#mobile-navi div.sub1 .menu:focus,
#mobile-navi div.sub1 .menu.here {
  background: #e2001a;
}
#mobile-navi div.sub2 {
  float: left;
  width: 100%;
  display: block;
  max-height: 0;
  overflow: hidden;
  -o-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
#mobile-navi div.sub2 .menu {
  white-space: normal;
  background: #fff;
  color: #000;
}
#mobile-navi div.sub2 .menu:hover,
#mobile-navi div.sub2 .menu:focus,
#mobile-navi div.sub2 .menu.here {
  background: #e2001a;
  color: #fff;
}
#mobile-navi div.sub1 .item.cb-toggle-target-active div.sub2 {
  -o-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  max-height: 500px;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/******* development.less 2013-1-16 *******/
/*.unit {
  box-shadow: 0 0 0 1px red inset;
}
 .part {
   box-shadow: 0 0 0 1px blue inset;
}*/
/*# sourceMappingURL=./screen-small.css.map */